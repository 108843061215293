
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';

export default function SeoHead({
    site_name = "Bartech Staffing",
    page_name = "Staffing Agency | US and Canada Staffing | Bartech Staffing",
    description = "Find roles in Accountancy and Finance, Engineering, HR, IT, Manufacturing, Admin and Project Management in the US and Canada with Bartech Staffing.",
    canonical_link = "",
    og_image = "https://www.bartechstaffing.com/site-assets/images/og-image.jpg",
    og_img_alt = "Staffing Agency | US and Canada Staffing | Bartech Staffing"
}) {
    return (
        <>
            <SourceFlowHead
                metaObject={metaObject}
                title={page_name}
                description={description}
                additionalMetaTags={[{
                    property: 'og:site_name',
                    content: site_name
                }, {
                    property: 'title',
                    content: page_name
                },
                ]}
                images={[
                    {
                        url: og_image,
                        width: 1200,
                        height: 600,
                        alt: og_img_alt,
                        type: "image/jpeg",
                    },
                ]}
                locale={'en_GB'}
                canonical={canonical_link}
            />
        </>
    )
}